<template>
  <div style="margin-top: 50px">
    <div class="newsto" v-for="(item, key) in news" :key="item.id">
      <img src="../assets/img/homeimg/ic_dot.png" class="quan" />
      <div class="month">{{ dateFormat(item.created_at) }}</div>
      <div class="newsert">
        <div
          class="newser"
          :style="{
            'border-left': news.length == key + 1 ? '0' : '1px solid #d6d6d6',
          }"
        >
          <img :src="item.news_cover" alt="" @click="tonewsdetaile(item.id)" />
          <div class="shang" @click="tonewsdetaile(item.id)">
            <p>{{ item.news_title }}</p>
          </div>
          <div class="note" @click="tonewsdetaile(item.id)">
            <p>{{ item.news_content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="news_fy_wrap">
      <div class="news_fy">
        <van-pagination v-model="currentPage" :page-count="total_pages" mode="simple" @change="handleCurrentChange">
        <template #prev-text>
          <!-- <van-icon name="arrow-left" /> -->
          上一页
        </template>
        <template #next-text>
          下一页
          <!-- <van-icon name="arrow" /> -->
        </template>
      </van-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import { getNews } from "../api/api";
import Vue from "vue";
import { Pagination, Icon } from "vant";
Vue.use(Pagination);
Vue.use(Icon);

export default {
  data() {
    return {
      news: [],
      currentPage: 1,
      total_pages: 1
    };
  },
  created () {
    this.newsList()
  },
  mounted() {
  },
  methods: {
    backtop(){
        var timer = setInterval(function(){
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5); 
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          this.isTop = true;
          if(osTop === 0){
            clearInterval(timer);
          }
        },10)
    },
    newsList(){
      this.$axios.get('/pc/News', {
        params: {
          page: this.currentPage,
          per_page: 10
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.code === 1000) {
          this.news = res.data.data.lists
          this.total_pages = res.data.page.total_pages
        } else {
          // this.loading = true
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    tonewsdetaile(id) {
      this.$router.push("newdetaile/" + id);
    },
    dateFormat(date) {
      return date.substring(0, 10);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // console.log(val)
      this.newsList()
      this.backtop()
    }
  },
  // mounted() {
  //   getNews().then((res) => {
  //     this.news = res.data.data.lists
  //     this.total_pages = res.data.page.total_pages
  //     this.currentPage = res.data.page.current_page
  //   })
  // },
};
</script>
<style lang="stylus" scoped>
.month {
  font-size: 16px;
  font-family: DIN;
  color: #85878A;
  display: inline;
  margin-left: 0.1rem;
  height: 0.3rem;
}

.quan {
  margin-left: 0.11rem;
  width: 0.09rem;
  height: 0.09rem;
}

.newsto {
  .newser {
    margin-left: 0.15rem;
    border-left: 1px solid #D6D6D6;
    height: 1.2rem;

    img {
      margin-left: 0.1rem;
      width: 1.2rem;
      height: 0.8rem;
      border-radius: 4px;
      margin-top: 0.1rem;
    }

    .note {
      float: right;
      width: 2.2rem;
      margin-top: -0.4rem;
      font-size: 12px;
      color: #999999;
      line-height: 0.24rem;
      text-overflow: ellipsis;
      display: inline;
      height: 0.43rem;
      overflow: hidden;

      p {
        margin-top: 0.1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 0.15rem;
      }
    }

    .shang {
      float: right;
      width: 2.2rem;
      margin-top: 0.13rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #212121;
      line-height: 24px;

      p {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-right: 15px;
      }
    }
  }
}

.news_fy_wrap {
  width: 80%;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.news_fy{
  width: 100%;
  height: auto;
}
</style>
<style>
.van-pagination__page-desc {
  visibility: hidden;
  flex: 0;
}
.van-pagination__item {
  height: 40px;
  border-radius: 50px;
  background: linear-gradient(90deg, #fa7a70, #f85447);
  color: #ffffff;
  font-size: .15rem;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, .2); */
}
[class*=van-hairline]::after{
  border: 0px solid transparent;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.van-pagination__item--disabled, .van-pagination__item--disabled:active{
  color: #ffffff;
  background-color: transparent;
}
.van-pagination__item:last-child::after{
  border: 0;
}

</style>
