<template>
  <div class="centent">
      <div class="centent">
		<headers :marage="159" :selectIndex="5">
		</headers>
        <!-- banner -->
        <div class="banner"></div>
          <News />
      </div>
  </div>
</template>

<script>
import News from "../../components/news.vue";
	import headers from "../../components/header.vue";
export default {
  components: {
    News,
	headers
  },
};
</script>
<style lang="stylus" scoped>
.banner
  height 1.60rem
  width 100%
  background url(../../assets/img/myimg/banner3.png)
  background-size 100%
</style>
